<template>
  <div class="div_input_mobile flex" :class="worring ? 'input_worring' : ''">
    <el-input
      v-model="mobile"
      @focus="worring = false"
      @input="inputPhone"
      size="large"
      class="key_input"
      type="password"
      show-password
      maxlength="20"
      :placeholder="props.placeholder ? props.placeholder : '输入密码'"
    >
    </el-input>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, defineExpose } from "vue";

const props = defineProps(["modelValue", "placeholder"]);
const emit = defineEmits(["update:modelValue"]);

let mobile = ref("");
let worring = ref(false);
// let reg =
//   /^(((?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[0-9])(?=.*[^\\s0-9a-zA-Z])|(?=.*[a-zA-Z])(?=.*[^\\s0-9a-zA-Z]))[^\\s]+)$/;
onMounted(() => {
  mobile.value = props.modelValue;
});

function inputPhone() {
  emit("update:modelValue", mobile.value);
}

defineExpose({
  worring,
});
</script>

<style lang='scss' scoped>
.div_input_mobile {
  height: 60px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid transparent;
  overflow: hidden;
  user-select: none;
  transition: all $anim-time-fn-ease-out 0.25s;
}
.input_worring {
  border: 1px solid #f56c6c;
}
</style>